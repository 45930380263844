import React from "react";
import { graphql } from "gatsby";
import SwitchTheme from "components/SwitchTheme";
import Redactor from "components/Redactor";

import {
  ContentWrapperStyled,
  LogoWrapperStyled,
  TextStyled,
  ImageStyled,
  LogoListtyled,
  HeadlineStyled
} from "./LogosStyled";

const Logos = ({ pageBuilder } /* :PageBuilderListInterface */) => {
  const {
    logoSectionHeadline,
    logoSectionText,
    logoSectionLogos
  } = pageBuilder; // ListItemInterface[]

  const textContent = logoSectionText?.content;
  const compact = true;

  return (
    <SwitchTheme name={"alternative"}>
      <LogoWrapperStyled compact={compact}>
        <ContentWrapperStyled compact={compact}>
          {logoSectionHeadline && (
            <HeadlineStyled compact={compact}>
              {logoSectionHeadline}
            </HeadlineStyled>
          )}
          {textContent && (
            <TextStyled margin="0 0 50px 0" compact={compact}>
              <Redactor content={textContent} parseType="content" />
            </TextStyled>
          )}
          <LogoListtyled compact={compact}>
            {logoSectionLogos.map((logoSectionLogo, idx) => (
              <ImageStyled
                key={"logo-" + idx}
                objectFit="contain"
                fluid={logoSectionLogo}
                compact={compact}
              />
            ))}
          </LogoListtyled>
        </ContentWrapperStyled>
      </LogoWrapperStyled>
    </SwitchTheme>
  );
};

export default Logos;

export const query = graphql`
  fragment PageBuilderLogosQuery on Craft_PageBuilderLogos {
    logoSectionHeadline
    logoSectionText {
      content
    }
    logoSectionLogos {
      ...CraftImageFluid
    }
  }
`;
