import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Text from "components/Text";
import Image from "components/Image";
import Headline from "components/Headline";

export const ImageStyled = styled(Image)(
  {
    width: "200px !important", // otherwise Image overwrites with element styles
    height: "100px !important",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "20px 50px",

    [media(0, "mobile")]: {
      width: "100px !important",
      height: "70px !important",
      margin: "10px 30px",
    },
  },
  (props) => ({
    width: props.compact && "150px !important",
    height: props.compact && "75px !important",
    margin: props.compact && "10px 30px",
  })
);

export const ContentWrapperStyled = styled("div")({
  position: "relative",
  padding: "0 45px 0 45px",
  width: "100%",
  textAlign: "center",

  [media(0, "mobile")]: {
    padding: "0 10px 0 10px",
    width: "100%",
  },
});

export const HeadlineStyled = styled(Headline)(
  {
    marginBottom: 20,
  },
  (props) => ({})
);

export const LogoWrapperStyled = styled("div")(
  {
    padding: "70px 0 100px",
    background: "white",
    position: "relative",
    zIndex: 1,
  },
  (props) => ({
    padding: props.compact && "35px 0 60px",
  })
);

export const TextStyled = styled("div")(
  {
    whiteSpace: "pre-line",
  },
  (props) => ({
    margin: props.compact && "0 0 25px 0;",
  })
);

export const LogoListtyled = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
});
